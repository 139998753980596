<template>
    <div class="d-flex">
        <v-img
            class="mid-w hide-small"
            lazy-src="/img/building-lg.2262d91c-lazy.jpg"
            src="/img/building-lg.2262d91c.jpg"
        ></v-img>
        <div class="mid-w">
            <v-card
                class="mx-auto"
                width="400"
            >
                <h1 class="primary text-center white--text py-1">MiSalud</h1>
                <v-card-text>
                    <h2 class="text-center">Inicio de Sesión</h2>
                    <p class="text-center">¿Nuevo Usuario? <router-link to="/signup"
                        >Crea una Cuenta</router-link
                    ></p>
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >

                        <v-text-field
                            type="email"
                            v-model="email"
                            :rules="emailRules"
                            label="E-mail"
                            required
                        ></v-text-field>

                        <v-text-field
                            type="password"
                            v-model="password"
                            :rules="passwordRules"
                            label="Contraseña"
                            required
                        ></v-text-field>

                        <div class="mb-3">
                            <router-link to="/forgotPassword"
                                >¿Olvidó su contraseña?</router-link
                            >
                        </div>

                        <v-btn
                            block
                            :disabled="!valid"
                            color="primary"
                            class="mr-4"
                            @click="validate"
                        >
                        Entrar
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import Session from "@/modules/session";

  export default {
    data: () => ({
      valid: true,
      email: '',
      emailRules: [
        v => !!v || 'E-mail requerido',
        v => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
      ],
      password: '',
      passwordRules: [
        v => !!v || 'Contraseña requerida'
      ],
    }),

    methods: {
      async validate () {
        if (this.$refs.form.validate()) {
            const result = new Session();
            try {
                await result.signInEmail(this.email, this.password);
                this.$router.push({ name: "Loader" });      
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Correo Electrónico o Contraseña Incorrectos",
                    toast: true,
                    position: "bottom",
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                    didOpen: toast => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                    }
                });   
            }
        }
      }
    },
  }
</script>

<style lang="css">
    .mid-w {
        height: 100vh;
        width: 50vw;
        display: flex;
        align-items: center;
    }
    @media only screen and (max-width: 600px) {
        .hide-small {
            display: none;
        }
        .mid-w {
            width: 100vw;
        }
    }
</style>